import './assets/styles.css';

import { HydraAdmin } from '@api-platform/admin';
import { EditGuesser, InputGuesser, ShowGuesser } from '@api-platform/admin';
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import BusinessIcon from '@material-ui/icons/Business';
import NotesIcon from '@material-ui/icons/Notes';
import PeopleIcon from '@material-ui/icons/People';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { useEffect, useState } from 'react';
import React from 'react';
import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  DateField,
  Labeled,
  List,
  NullableBooleanInput,
  ReferenceInput,
  Resource,
  TextField,
  TextInput
} from 'react-admin';
import { Filter, Login } from 'react-admin';

import authProvider from './authProvider';
import dataProvider from './dataProvider';
import MyLayout from './layout';

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT; // Change this by your own entrypoint if you're not using API Platform distribution

const theme = createTheme({
  palette: {
    primary: {
      light: '#0bbf97',
      main: '#007bff',
      dark: '#ffd447',
      contrastText: '#fff'
    },
    secondary: {
      light: '#964ccd',
      main: '#007bff',
      dark: '#ffd447',
      contrastText: '#000'
    }
  }
});

const DashboardItem = ({ icon: Icon, label, data }) => (
  <Grid
    item
    style={{ display: 'flex', alignItems: 'center', maxWidth: 'fit-content' }}
  >
    <Icon style={{ marginRight: '1rem' }} />
    <Typography variant="body1" style={{ flexGrow: 1 }}>
      {label}
    </Typography>
    <Typography variant="body1" style={{ marginLeft: '1rem' }}>
      {data}
    </Typography>
  </Grid>
);

const Dashboard = () => {
  const [data, setData] = useState({ subscriptions: 0, seats: 0 });

  useEffect(() => {
    fetch(entrypoint + '/admin/dashboard', {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Dashboard</Typography>
        <Grid
          container
          spacing={2}
          direction="column"
          style={{ width: 'fit-content', marginTop: '1rem' }}
        >
          <DashboardItem
            icon={BusinessIcon}
            label="Subscribed Organizations:"
            data={data.subscriptions}
          />
          <DashboardItem
            icon={PeopleIcon}
            label="Seats from Subscribed Organizations:"
            data={data.seats}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

const OrganizationList = props => (
  <List
    {...props}
    sort={{ field: 'createdAt', order: 'DESC' }}
    hasEdit={true}
    hasCreate={false}
    bulkActionButtons={false}
  >
    <Datagrid rowClick={'edit'}>
      <TextField source={'name'} label={'Name'} sortBy={'name'} />
      <TextField
        source={'createdAtDate'}
        label={'Date Organization Created'}
        sortBy={'createdAt'}
      />
      <TextField
        source={'isSubscriptionActive'}
        label={'Is Currently Subscribed?'}
        sortable={false}
      />
      <TextField
        source={'numberOfSeats'}
        label={'Seat Count'}
        sortable={false}
      />
      <TextField
        source={'numberOfTemplatesCreated'}
        label={'Number of Templates Created'}
      />
      <TextField
        source={'numberOfTemplatesPurchased'}
        label={'Number of Templates Purchased'}
      />
      <TextField
        source={'canCreatePurchasableProcedures'}
        label={'Can Create Purchasable Procedures'}
      />
    </Datagrid>
  </List>
);

const OrganizationEdit = props => (
  <EditGuesser
    {...props}
    sort={{ field: 'createdAt', order: 'DESC' }}
    hasEdit={true}
    hasCreate={false}
    bulkActionButtons={false}
  >
    <TextField source={'id'} label={'Id'} />
    <TextField source={'name'} label={'Name'} />
    <InputGuesser
      source={'canCreatePurchasableProcedures'}
      label={'Can Create Purchasable Procedures'}
    />
  </EditGuesser>
);

const UserFilter = props => {
  return (
    <Filter {...props}>
      <ReferenceInput
        source={'organization'}
        reference={'organizations'}
        resettable
        sort={{ field: 'name', order: 'ASC' }}
        alwaysOn
        perPage={1000}
        filterToQuery={searchText => ({ name: searchText })}
      >
        <AutocompleteInput
          optionText="name"
          options={{ InputProps: { autoComplete: 'no' } }}
        />
      </ReferenceInput>
      <TextInput source={'name'} label={'Name'} alwaysOn />
      <TextInput source={'email'} label={'Email'} alwaysOn />
      <NullableBooleanInput
        source={'didAgreeToTerms'}
        label={'Agreed to Terms?'}
        style={{ width: '200px' }}
        falseLabel={'Explicit False'}
        nullLabel={'Any'}
        trueLabel={'True'}
        alwaysOn
      />
      {/* Has issues in Safari, cannot reset it */}
      {/*<DateInput*/}
      {/*  className={'custom-date-input-width'}*/}
      {/*  label={'Password Requested After'}*/}
      {/*  source={'passwordRequestedAt[after]'}*/}
      {/*  resettable*/}
      {/*  InputLabelProps={{ shrink: true }}*/}
      {/*/>*/}
    </Filter>
  );
};

const UserList = props => {
  return (
    <List
      {...props}
      filters={<UserFilter />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      hasEdit={false}
      hasCreate={false}
      bulkActionButtons={false}
    >
      <Datagrid rowClick={'show'}>
        <TextField source={'name'} label={'Name'} sortBy={'name'} />
        <TextField source={'email'} label={'Email'} sortBy={'email'} />
        <TextField
          source={'organizationName'}
          label={'Organization Name'}
          sortable={false}
        />
        <TextField
          source={'countOfProceduresCreated'}
          label={'Created Procedures'}
          sortable={false}
        />
        <TextField
          source={'countOfUsedProcedures'}
          label={'Used Procedures'}
          sortable={false}
        />
        <TextField
          source={'createdAtDateTime'}
          label={'Created'}
          sortBy={'createdAt'}
        />
        <TextField
          source={'passwordRequestedAtDateTime'}
          label={'Password Last Requested'}
          sortBy={'passwordRequestedAt'}
        />
        <BooleanField
          source={'didAgreeToTerms'}
          label={'Agreed to Terms?'}
          sortBy={'didAgreeToTerms'}
        />
        <DateField
          source={'termsAgreedAt'}
          label={'Terms Agreed At'}
          sortBy={'termsAgreedAt'}
          showDate
          showTime
        />
      </Datagrid>
    </List>
  );
};

const UserShow = props => (
  <ShowGuesser {...props}>
    <Box margin={2}>
      <Typography variant="h6" gutterBottom>
        User Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} container spacing={3}>
          <Grid item>
            <Labeled label="Created Date">
              <TextField source="createdAtDate" />
            </Labeled>
          </Grid>
          <Grid item>
            <Labeled label="Created At">
              <TextField source="createdAtDateTime" />
            </Labeled>
          </Grid>
          <Grid item>
            <Labeled label="Is Continue Onboarding Disabled">
              <BooleanField source="isContinueOnboardingDisabled" />
            </Labeled>
          </Grid>
        </Grid>

        <Grid item xs={12} container spacing={3}>
          <Grid item>
            <Labeled label="Name">
              <TextField source="name" />
            </Labeled>
          </Grid>
          <Grid item>
            <Labeled label="Email">
              <TextField source="email" />
            </Labeled>
          </Grid>
          <Grid item>
            <Labeled label="Organization Name">
              <TextField source="organizationName" />
            </Labeled>
          </Grid>
        </Grid>

        <Grid item xs={12} container spacing={3}>
          <Grid item>
            <Labeled label="Role Name">
              <TextField source="roleName" />
            </Labeled>
          </Grid>
          <Grid item>
            <Labeled label="Enabled Name">
              <TextField source="enabledName" />
            </Labeled>
          </Grid>
          <Grid item>
            <Labeled label="With Token">
              <BooleanField source="withToken" />
            </Labeled>
          </Grid>
        </Grid>

        <Grid item xs={12} container spacing={3}>
          <Grid item>
            <Labeled label="Password Requested At">
              <TextField source="passwordRequestedAtDateTime" />
            </Labeled>
          </Grid>
          <Grid item>
            <Labeled label="Confirmation Token">
              <TextField source="usersConfirmationToken" />
            </Labeled>
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={3}>
          <Grid item>
            <Labeled label="Terms Agreed At">
              <TextField source="termsAgreedAt" />
            </Labeled>
          </Grid>
          <Grid item>
            <Labeled label="Agreed to Terms?">
              <TextField source="didAgreeToTerms" />
            </Labeled>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  </ShowGuesser>
);

const ProcedureFilter = props => {
  return (
    <Filter {...props}>
      <ReferenceInput
        source={'organization'}
        reference={'organizations'}
        resettable
        sort={{ field: 'name', order: 'ASC' }}
        alwaysOn
        perPage={1000}
        filterToQuery={searchText => ({ name: searchText })}
      >
        <AutocompleteInput
          optionText="name"
          options={{ InputProps: { autoComplete: 'no' } }}
        />
      </ReferenceInput>
    </Filter>
  );
};

const ProcedureList = props => (
  <List
    {...props}
    filters={<ProcedureFilter />}
    sort={{ field: 'updatedAt', order: 'DESC' }}
    hasEdit={false}
    hasCreate={false}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source={'name'} label={'Procedure Name'} sortBy={'name'} />
      <TextField
        source={'organizationName'}
        label={'Organization Name'}
        sortable={false}
      />
      <TextField
        source={'createdByName'}
        label={'Created By'}
        sortBy={'createdBy.name'}
      />
      <TextField
        source={'countOfProcedureSteps'}
        label={'Count of Steps'}
        sortable={false}
      />
      <TextField
        source={'countOfUsedProcedures'}
        label={'Times Used'}
        sortable={false}
      />
      <TextField
        source={'latestUpdatedAtOfUsedProcedures'}
        label={'Last Time Used'}
        // sortable={false}
      />
      <TextField
        source={'createdAtDateTime'}
        label={'Created At'}
        sortBy={'createdAt'}
      />
      <TextField
        source={'updatedAtDateTime'}
        label={'Updated At'}
        sortBy={'updatedAt'}
      />
    </Datagrid>
  </List>
);

export default () => (
  <HydraAdmin
    authProvider={authProvider}
    entrypoint={entrypoint}
    dataProvider={dataProvider}
    loginPage={Login}
    theme={theme}
    layout={MyLayout}
    dashboard={Dashboard}
  >
    <Resource
      name={'organizations'}
      list={OrganizationList}
      edit={OrganizationEdit}
      icon={SupervisorAccountIcon}
    />
    <Resource
      name={'users'}
      list={UserList}
      icon={SupervisorAccountIcon}
      show={UserShow}
    />
    <Resource name={'procedures'} list={ProcedureList} icon={NotesIcon} />
  </HydraAdmin>
);
